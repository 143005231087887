import {Inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {concat, debounce, take} from 'rxjs';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {BALANCE} from '../../tokens/balance';

@Injectable()
export class UserCornerService {
  public readonly lazyUserBalance$ = concat(
    this._balance.pipe(take(1)),
    this._balance.pipe(debounce(() => this._matDialog.afterAllClosed)),
  );

  public constructor(
    @Inject(BALANCE)
    private readonly _balance: AzaCdkInjectionTokenType<typeof BALANCE>,
    private readonly _matDialog: MatDialog,
  ) {}
}
