<div [class]="view('balance')">
  <aza-core-aza-icon></aza-core-aza-icon>

  <div [class]="view('balanceContent')">
    <span [class]="view('balanceTitle')" i18n>Balance:</span>

    @let balance = balance$ | async;
    @if (balance !== null) {
      <aza-core-animated-currency-amount
        [class]="view('balanceValue')"
        [amount]="balance | azaCoreTrunc"
        [title]="balance"
      ></aza-core-animated-currency-amount>
    } @else {
      <span [class]="view('balanceValue')">0</span>
    }
  </div>
</div>

<ng-content></ng-content>
